import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Club } from '@models/admin-models';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ClubInfoService } from '../admin/club-info.service';

@Injectable({
  providedIn: 'root',
})
export class ClubResolver  {
  constructor(private clubSvc: ClubInfoService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Club> {
    return this.clubSvc.club_detail(Number(route.params['club'])).pipe(first());
  }
}
